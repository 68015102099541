import React, { useState } from "react";
import "./Calendario.css";

const Calendario = ({ onFechaSeleccionada }) => {
  const [yearSeleccionado, setYearSeleccionado] = useState("");

  const handleYearChange = (event) => {
    const year = parseInt(event.target.value, 10);
    setYearSeleccionado(year);
    onFechaSeleccionada(year);
  };

  return (
    <div className="calendario-container">
      <select className="calendario" value={yearSeleccionado} onChange={handleYearChange}>
        <option value="" disabled>
        </option>
        {Array.from({ length: 16 }, (_, index) => 2010 + index).map((year) => (
          <option key={year} value={year} className="calendario-opt">
            {year}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Calendario;
